import React from "react";
import { Helmet } from "react-helmet";
import ContactIntro from "./ContactIntro/ContactIntro";
import getSolarStyle from "./getSolar.module.scss";

const GetSolar = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="shreyas" content="Place the meta description text here." />
        <title>NLE</title>
        <link rel="canonical" href="/" />
      </Helmet>
      <section className={getSolarStyle.contactFormSection}>
        <ContactIntro/>
      </section>
    </>
  );
};

export default GetSolar;
