import React, { useRef, useState } from "react";
import solutionPageStyle from "./solution.module.scss";
import solPage1 from "../../Assets/Solution/solPage1.svg";
import solPage2 from "../../Assets/Solution/solPage2.svg";
import solPage3 from "../../Assets/Solution/solPage3.svg";
import solPage4 from "../../Assets/Solution/solPage4.svg";
import solPage5 from "../../Assets/Solution/solPage5.svg";
import solPage6 from "../../Assets/Solution/solPage6.svg";
// import solRooftop from "../../Assets/Solution/solRooftop.png";
import solScroll from "../../Assets/Solution/solScroll.svg";
import Slider from "react-slick";
import Rooftop from "../../Constants/Rooftop";
import Microgrid from "../../Constants/Microgrid";
import Openaccess from "../../Constants/Openaccess";
import Evcharging from "../../Constants/Evcharging";
import Hydrosolar from "../../Constants/Hydrosolar";
import Bipv from "../../Constants/Bipv";
import Bms from "../../Constants/Bms";

const solutionTabs = [
  { image: solPage1, alternate: "logo", text: "All" },
  { image: solPage2, alternate: "logo", text: "Industrial" },
  { image: solPage3, alternate: "logo", text: "Residential" },
  { image: solPage4, alternate: "logo", text: "Commercial" },
  { image: solPage5, alternate: "logo", text: "Data Centre" },
  { image: solPage6, alternate: "logo", text: "Industrial Park" },
];



const optionOne = [
  { listText: "ROOFTOP PV" },
  { listText: "Micro Grid (ESS) - R.T.C." },
  { listText: "OPEN ACCESS SOLAR" },
  { listText: "E.V. CHARGING" },
  { listText: "DECENTRALISED HYDROGEN SOLAR" },
  { listText: "B.I.P.V." },
  { listText: "B.M.S. - AUTOMATION FOR UTILITY EFFICIENCY" },
];
const optionTwo = [
  { listText: "ROOFTOP PV" },
  { listText: "MICROGRID (ESS) - R.T.C." },
  { listText: "OPEN ACCESS SOLAR" },
  { listText: "E.V. CHARGING" },
  { listText: "DECENTRALISED HYDROGEN SOLAR" },
];

const optionThree = [
  { listText: "ROOFTOP PV" },
  { listText: "B.I.P.V." },
  { listText: "E.V. CHARGING" },
  { listText: "B.M.S. - AUTOMATION FOR UTILITY EFFICIENCY" },
];

const optionFour = [
  { listText: "ROOFTOP PV" },
  { listText: "B.I.P.V." },
  { listText: "E.V. CHARGING" },
  { listText: "B.M.S. - AUTOMATION FOR UTILITY EFFICIENCY" },
];

const optionFive = [
  { listText: "ROOFTOP PV" },
  { listText: "MICROGRID (ESS) – R.T.C." },
  { listText: "OPEN ACCESS SOLAR" },
  { listText: "E.V. CHARGING" },
  { listText: "DECENTRALISED HYDROGEN SOLAR" },
  { listText: "B.M.S. - AUTOMATION FOR UTILITY EFFICIENCY" },
];

const optionSix = [
  { listText: "ROOFTOP PV" },
  { listText: "MICROGRID (ESS) – R.T.C." },
  { listText: "OPEN ACCESS SOLAR" },
  { listText: "E.V. CHARGING" },
  { listText: "DECENTRALISED HYDROGEN SOLAR" },
  { listText: "B.M.S. - AUTOMATION FOR UTILITY EFFICIENCY" },
];

const Solution = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeOption, setActiveOption] = useState(0);
  const sliderRef = useRef(null);

  const handleTabClick = (index) => {
    setActiveTab(index);
    setActiveOption(0);
  };

  const handleOptionClick = (index) => {
    setActiveOption(index);
    sliderRef.current.slickGoTo(index);

    // console.log("option active", activeOption);
    // console.log("index active", index);
  };

  // for the slider
  const solutionSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessiibility: true,
    autoplay: false,
    // autoplaySpeed: 5000,
    arrows: true,
    pauseOnHover: true,
    draggable: true,
    padding: 0,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={`container-fluid ${solutionPageStyle.solPageContainer}`}>
        {/* <h1 className={solutionPageStyle.solPageHeading}><span>Consumer </span>Type</h1> */}
        <div className="row">
          <div className={`col-md-12 col-lg-12 ${solutionPageStyle.tabsCol}`}>
            {/* for solution tabs */}
            <div className={`row ${solutionPageStyle.tabsRow}`}>
              {solutionTabs.map((tab, index) => (
                <div className="col-4 col-sm-4 col-md-4 col-lg-2">
                  <div
                    key={index}
                    className={
                      index === activeTab
                        ? solutionPageStyle.activeTab
                        : solutionPageStyle.inactiveTab
                    }
                    onClick={() => handleTabClick(index)}
                  >
                    <img src={tab.image} alt={tab.alternate} />
                    <p>{tab.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={`col-md-12 col-lg-12 ${solutionPageStyle.optionCol}`}>
            {/* for tabs options */}

            {activeTab === 0 && (
              <>
                <ul className={solutionPageStyle.ulOption}>
                  {optionOne.map((option, index) => (
                    <li
                      key={index}
                      className={
                        index === activeOption
                          ? solutionPageStyle.activeOpStyle
                          : solutionPageStyle.inactiveOpStyle
                      }
                      onClick={() => handleOptionClick(index)}
                    >
                      {option.listText}
                    </li>
                  ))}
                </ul>
              </>
            )}

            {activeTab === 1 && (
              <>
                <ul className={solutionPageStyle.ulOption}>
                  {optionTwo.map((option, index) => (
                    <li
                      key={index}
                      className={
                        index === activeOption
                          ? solutionPageStyle.activeOpStyle
                          : solutionPageStyle.inactiveOpStyle
                      }
                      onClick={() => handleOptionClick(index)}
                    >
                      {option.listText}
                    </li>
                  ))}
                </ul>
              </>
            )}

            {activeTab === 2 && (
              <>
                <ul className={solutionPageStyle.ulOption}>
                  {optionThree.map((option, index) => (
                    <li
                      key={index}
                      className={
                        index === activeOption
                          ? solutionPageStyle.activeOpStyle
                          : solutionPageStyle.inactiveOpStyle
                      }
                      onClick={() => handleOptionClick(index)}
                    >
                      {option.listText}
                    </li>
                  ))}
                </ul>
              </>
            )}

            {activeTab === 3 && (
              <>
                <ul className={solutionPageStyle.ulOption}>
                  {optionFour.map((option, index) => (
                    <li
                      key={index}
                      className={
                        index === activeOption
                          ? solutionPageStyle.activeOpStyle
                          : solutionPageStyle.inactiveOpStyle
                      }
                      onClick={() => handleOptionClick(index)}
                    >
                      {option.listText}
                    </li>
                  ))}
                </ul>
              </>
            )}

            {activeTab === 4 && (
              <>
                <ul className={solutionPageStyle.ulOption}>
                  {optionFive.map((option, index) => (
                    <li
                      key={index}
                      className={
                        index === activeOption
                          ? solutionPageStyle.activeOpStyle
                          : solutionPageStyle.inactiveOpStyle
                      }
                      onClick={() => handleOptionClick(index)}
                    >
                      {option.listText}
                    </li>
                  ))}
                </ul>
              </>
            )}

            {activeTab === 5 && (
              <>
                <ul className={solutionPageStyle.ulOption}>
                  {optionSix.map((option, index) => (
                    <li
                      key={index}
                      className={
                        index === activeOption
                          ? solutionPageStyle.activeOpStyle
                          : solutionPageStyle.inactiveOpStyle
                      }
                      onClick={() => handleOptionClick(index)}
                    >
                      {option.listText}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <div
            className={`col-12 col-sm-12 col-md-12 col-lg-12 ${solutionPageStyle.sliderCol}`}
          >
            {/* for the final display */}

            {activeTab === 0 && (
              <>
                <Slider
                  ref={sliderRef}
                  {...solutionSettings}
                  afterChange={handleOptionClick}
                >
                  <Rooftop />
                  <Microgrid />
                  <Openaccess />
                  <Evcharging />
                  <Hydrosolar />
                  <Bipv />
                  <Bms />
                </Slider>
              </>
            )}

            {/* second tab */}
            {activeTab === 1 && (
              <>
                <Slider
                  ref={sliderRef}
                  {...solutionSettings}
                  afterChange={handleOptionClick}
                >
                <Rooftop/>
                <Microgrid/>
                <Openaccess/>
                <Evcharging/>
                <Hydrosolar/>
                </Slider>
              </>
            )}

            {/* Third tab */}
            {activeTab === 2 && (
              <>
                <Slider
                  ref={sliderRef}
                  {...solutionSettings}
                  afterChange={handleOptionClick}
                >
                  <Rooftop/>
                  <Bipv/>
                  <Evcharging/>
                  <Bms/>
                </Slider>
              </>
            )}

            {/* Fourth tab */}
            {activeTab === 3 && (
              <>
                <Slider
                  ref={sliderRef}
                  {...solutionSettings}
                  afterChange={handleOptionClick}
                >
                   <Rooftop/>
                  <Bipv/>
                  <Evcharging/>
                  <Bms/>
                </Slider>
              </>
            )}

            {/* Fifth tab */}
            {activeTab === 4 && (
              <>
                <Slider
                  ref={sliderRef}
                  {...solutionSettings}
                  afterChange={handleOptionClick}
                >
                  <Rooftop/>
                  <Microgrid/>
                  <Openaccess/>
                  <Evcharging/>
                  <Hydrosolar/>
                  <Bms/>
                </Slider>
              </>
            )}

            {/* sixth tab */}
            {activeTab === 5 && (
              <>
                <Slider
                  ref={sliderRef}
                  {...solutionSettings}
                  afterChange={handleOptionClick}
                >
                   <Rooftop/>
                  <Microgrid/>
                  <Openaccess/>
                  <Evcharging/>
                  <Hydrosolar/>
                  <Bms/>
                </Slider>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Solution;
