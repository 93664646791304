import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import logo from '../../../Assets/header and footer/Layer1000.svg'
import { NavLink } from "react-router-dom";


const Header = ({handleLinkClick, activeLink}) => {

  
  const [isMobile, setIsMobile] = useState(false);

  // for window size recognition begins
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 576px)");

    // defining callbacks
    function handleMediaQueryChange(e) {
      setIsMobile(e.matches);
    }

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // checking size initially
    setIsMobile(mediaQuery.matches);

    // Clean up by removing eventListener
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  // ends


  // for link clicked
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);






  if (isMobile) {
    return (
      <>
        <div class={`container-fluid ${styles.headerContainer}`}>
          <nav className={`navbar navbar-expand-lg navbar-light ${styles.headerLogo}`}>
            <NavLink className="navbar-brand " to={"/"}>
              <img src={logo} alt="brand logo" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleNavCollapse}
              // data-bs-toggle="collapse"
              // data-bs-target="#navbarTogglerDemo01"
              // aria-controls="navbarTogglerDemo01"
              // aria-expanded="false"
              // aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div   className={`${
          isNavCollapsed ? "collapse" : ""
        } navbar-collapse`} id="navbarTogglerDemo01">
              <ul className="navbar-nav ms-auto">
                <li className="navbar-item">
                  <NavLink className="nav-link" to={"/"} onClick={handleNavCollapse}>
                    Home
                  </NavLink>
                </li>
                <li className="navbar-item">
                  <a className="nav-link" href="https://ziziev.com/" target="_blank" rel="noreferrer" onClick={handleNavCollapse}>
                   ZIZI
                  </a>
                </li>
                <li className="navbar-item">
                  <NavLink className="nav-link" to={"/solution"} onClick={handleNavCollapse}>
                    Solution
                  </NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="nav-link" to={"/energy-financing"} onClick={handleNavCollapse}>
                    Energy Financing
                  </NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="nav-link" to= {"/about-us"} onClick={handleNavCollapse}>
                    About Us
                  </NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="nav-link" to= {"/career"} onClick={handleNavCollapse}>
                    Career
                  </NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="nav-link" to={"/getSolar"} onClick={handleNavCollapse}>
                    <button className={styles.joinUsBtn}>Get Solar</button>
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </>
    );
  } else {
    return (
      <>
      {/* <div className="fixed-top"> */}
        <div className={`container-fluid ${styles.nleHeader}`}>
          <div
            className={`row ${styles.headerContainer} justify-content-center align-items-center`}
          >
            <div className={`col-sm-2 col-md-2 col-lg-2 d-flex justify-content-start ${styles.headerLogo}`} >
              <NavLink to={"/"} onClick={() => handleLinkClick(5)}>
              <img src={logo} alt="logo" className="img-fluid" />
              </NavLink>
            </div>
            <div className=" col-sm-8 col-md-8 d-flex justify-content-center px-0">
              <ul className={styles.headerList}>
              <li
                    className={`nav-li`}
                  ><NavLink className={(activeLink === 0 || activeLink === 5) ? `${styles.activeHeaderLink} nav-link`: "nav-link"} to={`/`} onClick={() => handleLinkClick(0)} href="/">
                      Home
                    </NavLink></li>
                    <li
                    className={'nav-li'}
                  >
                     <a className={activeLink === 1 ? `${styles.activeHeaderLink} nav-link`: "nav-link"} onClick={() => handleLinkClick(1)}  href="https://ziziev.com/" target="_blank" rel="noreferrer">
                      ZIZI
                    </a></li>
                    <li 
                    className={'nav-li'}
                  ><NavLink className={activeLink === 2 ? `${styles.activeHeaderLink} nav-link`: "nav-link"} to={`/solution`} onClick={() => handleLinkClick(2)} >
                     Solution
                    </NavLink></li>
                    <li 
                    className={'nav-li'}
                  ><NavLink className={activeLink === 3 ? `${styles.activeHeaderLink} nav-link`: "nav-link"} to={`/energy-financing`} onClick={() => handleLinkClick(3)} >
                      Energy Financing
                    </NavLink></li>
                    <li 
                    className={'nav-li'}
                  ><NavLink className={activeLink === 4 ? `${styles.activeHeaderLink} nav-link`: "nav-link"} to={`/about-us`} onClick={() => handleLinkClick(4)}>
                      About Us
                    </NavLink></li>
                    <li 
                    className={'nav-li'}
                  ><NavLink className={activeLink === 7 ? `${styles.activeHeaderLink} nav-link`: "nav-link"} to={`/career`} onClick={() => handleLinkClick(7)}>
                      Career
                    </NavLink></li>
              </ul>
            </div>
            <div className="col-sm-2 col-md-2 px-0 d-flex justify-content-end ">
              <NavLink to="/getSolar" onClick={() => handleLinkClick(6)}>
              <button className={styles.joinUsBtn}>Get Solar</button>
              </NavLink>
            </div>
          </div>
        </div>
        {/* </div> */}
      </>
    );
  }
};

export default Header;