import React, { useState } from "react";
import Header from "./Header/Header";
//  import Footer from "./Footer/Footer";
import layoutStyle from "./BasicLayout.module.scss";
import Footer from "./Footer/Footer";

const BasicLayout = ({ children, handleLinkClick, activeLink}) => {


  return (
    <>
      {/* <Header /> */}
      <div className="scroller">
        <section  className={layoutStyle.headerSection}>
        <Header handleLinkClick={handleLinkClick} activeLink={activeLink} />
        </section>
            <div>{children}</div>
        <Footer handleLinkClick={handleLinkClick} activeLink={activeLink}/>
      </div>

   
    </>
  );
};

export default BasicLayout;
