import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../Layouts/BasicLayout/Footer/Footer";
import AboutIntro from "./Components/AboutIntro/AboutIntro";
import AboutMessage from "./Components/AboutMessage/AboutMessage";
import AboutStats from "./Components/AboutStats/AboutStats";
import Header from "../../Layouts/BasicLayout/Header/Header";
import homeStyle from "./aboutUs.module.scss";
import aboutStyle from "./aboutUs.module.scss";
import AboutTimeline from "./Components/AboutTimeline/AboutTimeline";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="shreyas" content="Place the meta description text here." />
        <title>NLE</title>
        <link rel="canonical" href="/" />
      </Helmet>

      <section className={aboutStyle.aboutIntroSection}>
        <AboutIntro />
      </section>

      <section className={aboutStyle.timelineSection}>
        <AboutTimeline/>
      </section>

    </>
  );
};

export default AboutUs;
