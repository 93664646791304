import { useEffect, useState } from "react";
import projectStyle from "./projects.module.scss";

const Projects = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseEnter = (event) => {
    event.stopPropagation();
    setIsHovering(true)
  }

  const handleMouseLeave = (event) => {
    event.stopPropagation();
    setIsHovering(false);
  }




  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 576px)");

    // defining callbacks
    function handleMediaQueryChange(e) {
      setIsMobile(e.matches);
    }

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // checking size initially
    setIsMobile(mediaQuery.matches);

    // Clean up by removing eventListener
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  if (isMobile) {
    return (
      <div className={`container-fluid ${projectStyle.projectContainer}`}>
        <div>
          <div className={projectStyle.projectOne}></div>
          <div className={projectStyle.projectOneText}>
            <h1>Industrial Revolution - Rooftop PV</h1>
            <p>Industrial usage counts for more than 40% of total electricity in India. NLE’s Industrial Revolution is an effort where we assist industries to offset a portion of their electric consumption to rooftop solar.</p>
            <div className={projectStyle.installLocations}>
              <p>Capacity : 1.7 MWdc</p>
              <p>Installation: Rooftop – Industrial Shed</p>
              <p>Location: Mumbai</p>
              </div>
          </div>
        </div>

        <div>
          <div className={projectStyle.projectTwo}></div>
          <div className={projectStyle.projectTwoText}>
            <h1>Sustainable Farming - Agrivoltaic Microgrid</h1>
            <p>Living in harmony, is a thought that will help us in our fight with climate change. NLE - Agrivoltaic follows this concept to install P.V. microgrids for providing robust - dependable clean energy without affecting the primary use of land i.e. farming.</p>
            <div className={projectStyle.installLocations}>
              <p>Capacity: 470 kWdc / 250 kWh E.S.S</p>
              <p>Installation: Ground Mount, Shed and Floating</p>
              <p>Location: Pune</p>
              </div>
          </div>
        </div>

        <div>
          <div className={projectStyle.projectThree}></div>
          <div className={projectStyle.projectThreeText}>
            <h1>Commercial Zone – Solar Roof</h1>
            <p>I.T. Parks, Datacenters, Hospitals, Malls, Hotels currently exposed to the highest tariff making switch to solar not only a social responsibility but also an economic strategy. NLE provides high performance but also aesthetically pleasing solar installations that adds charm to your building.</p>
            <div className={projectStyle.installLocations}>
            
              <p>Capacity: 840 kWdc</p>
              <p>Installation: Rooftop – R.C.C.</p>
              <p>Location: Pune</p>
              </div>
          </div>
        </div>

        <div>
          <div className={projectStyle.projectFour}></div>
          <div className={projectStyle.projectFourText}>
            <h1>True North Park – 30 MW</h1>
            <p>A state-of-the-art Solar Farm with high performance generation plant accompanied by elite guest accommodation and tours. NLE – True North Park will provide clean energy to its commercial and industrial clients across the state of Maharashtra.</p>
            <div className={projectStyle.installLocations}>
              <p>Capacity: 30MW</p>
              <p>Installation: Ground Mount</p>
              <p>Location: Maharashtra</p>
              </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`container-fluid ${projectStyle.projectContainer}`}>
        <section className={projectStyle.projectSecOne}>
          <div
            className={projectStyle.projectOne}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className={projectStyle.projectTextOne}>
              <p>Industrial Revolution - Rooftop PV</p>
            </div>
            <div className={projectStyle.animationContainerOne}>
              <h1>Industrial Revolution - Rooftop PV</h1>
              <p>Industrial usage counts for more than 40% of total electricity in India. NLE’s Industrial Revolution is an effort where we assist industries to offset a portion of their electric consumption to rooftop solar.</p>
              <div className={projectStyle.installLocations}>
              <p>Capacity: 1.7 MWdc</p>
              <p>Installation: Rooftop – Industrial Shed</p>
              <p>Location: Mumbai</p>
              </div>
            </div>
          </div>

          <div className={projectStyle.projectTwo}>
            <div className={projectStyle.projectTextTwo}>
              <p>Sustainable Farming - Agrivoltaic Microgrid</p>
            </div>
            <div className={projectStyle.animationContainerTwo}>
              <h1>Sustainable Farming - Agrivoltaic Microgrid</h1>
              <p>Living in harmony, is a thought that will help us in our fight with climate change. NLE - Agrivoltaic follows this concept to install P.V. microgrids for providing robust - dependable clean energy without affecting the primary use of land i.e. farming.</p>
              <div className={projectStyle.installLocations}>
              <p>Capacity: 470 kWdc / 250 kWh E.S.S</p>
              <p>Installation: Ground Mount, Shed and Floating</p>
              <p>Location: Pune</p>
              </div>
            </div>
          </div>
        </section>

        <section className={projectStyle.projectSecTwo}>
          <div className={projectStyle.projectThree}>
            <div className={projectStyle.projectTextThree}>
              <p>Commercial Zone – Solar Roof</p>
            </div>
            <div className={projectStyle.animationContainerThree}>
              <h1>Commercial Zone – Solar Roof</h1>
              <p>I.T. Parks, Datacenters, Hospitals, Malls, Hotels currently exposed to the highest tariff making switch to solar not only a social responsibility but also an economic strategy. NLE provides high performance but also aesthetically pleasing solar installations that adds charm to your building.</p>
              <div className={projectStyle.installLocations}>
              <p>Capacity: 840 kWdc</p>
              <p>Installation: Rooftop – R.C.C.</p>
              <p>Location: Pune</p>
              </div>
            </div>
          </div>

          <div className={projectStyle.projectFour} >
            <div className={projectStyle.projectTextFour}>
              <p>True North Park – 30 MW</p>
            </div>
            <div className={projectStyle.animationContainerFour}>
              <h1>True North Park – 30 MW</h1>
              <p>A state-of-the-art Solar Farm with high performance generation plant accompanied by elite guest accommodation and tours. NLE – True North Park will provide clean energy to its commercial and industrial clients across the state of Maharashtra.</p>
              <div className={projectStyle.installLocations}>
              <p>Capacity: 30MW</p>
              <p>Installation: Ground Mount</p>
              <p>Location: Maharashtra</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default Projects;
