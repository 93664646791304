import React from 'react';
import solutionPageStyle from "../Pages/Solution/solution.module.scss";
import solScroll from "../Assets/Solution/solScroll.svg";

const Openaccess = () => {
  return (
   <>
       {/* third slide */}
       <div
                    className={`container-fluid ${solutionPageStyle.slideItem}`}
                  >
                    <div className="row">
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-2 order-sm-1 order-md-1 order-lg-1 ${solutionPageStyle.solLeftCol}`}
                      >
                        <div
                          className={`row ${solutionPageStyle.solLeftContent}`}
                        >
                          <div
                            className={`col-1 col-sm-1 col-md-1 col-lg-1 ${solutionPageStyle.scrollCol}`}
                          >
                            <img
                              src={solScroll}
                              className={solutionPageStyle.scrollPicStyle}
                              alt="scroll logo"
                            />
                          </div>
                          <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                            {/* <h1 className={solutionPageStyle.firstTextHeading}>
                              Industrial
                            </h1> */}
                            <h2 className={solutionPageStyle.secondTextHeading}>
                              OPEN ACCESS SOLAR
                            </h2>
                            <p className={solutionPageStyle.solParagraph}>
                              For clients with higher power consumption but
                              space constraints at their premises, Open Access
                              Solar is the solution. In this the Solar Plant is
                              installed at a remote location anywhere within the
                              state. This helps in maximizing solar power
                              integrationat a clients’ facility which does not
                              have enough space to install solar plant.
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Eligibility:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - Contract Demand > 1 MW
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Plant Location:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - Off Site – At NLE’s True North Solar Park
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Connection Type:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - Open Access
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Max. Solar Capacity:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - As per Contract Demand
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Installation Type:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - Ground Mount{" "}
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Additional Charges:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - Wheeling, Transmission, Additional Surcharge,
                              Cross Subsidy Charge
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-1 order-sm-2 order-md-2 order-lg-2 ${solutionPageStyle.solRightCol}`}
                      ></div>
                    </div>
                  </div>
   </>
  )
}

export default Openaccess;