import visionStyle from "./vision.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.scss";
import visionLogo1 from "../../../../Assets/Home/visionLogo1.svg";
import visionLogo2 from "../../../../Assets/Home/visionLogo2.svg";
import visionLogo3 from "../../../../Assets/Home/visionLogo3.svg";


import React from "react";

const Vision = () => {
  const visionSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessiibility: true,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    pauseOnHover: true,
    className: "center",
    centerMode: true,
    // centerPadding: '420px',
    draggable: true,
    responsive: [
      {
        breakpoint: 2144,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "420px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          centerPadding: "220px",
        },
      },


      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          centerPadding: "250px",
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          centerPadding: "120px",
        },
      },

      {
        breakpoint: 576,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          // centerMode:false,
          className: "center",
          centerMode: true,
          centerPadding: "5px",
        },
      },
    ],
  };

  return (
    <div className={`container-fluid ${visionStyle.visionContainer}`}>
      <div className={visionStyle.visionText}>
        <h3>
          Our <span>Purpose</span>
        </h3>
        {/* <p>Lorem ipsum dolor sit amet consectetur. Molestie pharetra justo gravida velit tortor tortor. Ut gravida varius vel velit.</p> */}
      </div>

      <Slider {...visionSettings}>
        <div className={visionStyle.itemContainer}>
          <img src={visionLogo1} alt="vision one" />
          <h6>We make renewable energy transition seamless</h6>
          <p>
          by delivering a holistic solution so simple that the client has absolutely no hindrance in their modus operandi{" "}
          </p>
        </div>

        <div className={visionStyle.itemContainer}>
          <img src={visionLogo2} alt="vision two" />
          <h6>We analyze, design and verify before delivering</h6>
          <p>
          to our customers so they are certain about deploying a state-of-the-art optimized power plant without any regulatory hassle{" "}
          </p>
        </div>

        <div className={visionStyle.itemContainer}>
          <img src={visionLogo3} alt="vision three" />
          <h6>We associate with our clients on a personal level</h6>
          <p>
          for an in house team experience, solving all their clean energy challenges by actively and constantly understanding their requirements{" "}
          </p>
        </div>
      </Slider>
    </div>
  );
};

export default Vision;
