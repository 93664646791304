import React from 'react';
import solutionPageStyle from "../Pages/Solution/solution.module.scss";
import solScroll from "../Assets/Solution/solScroll.svg";

const Rooftop = () => {
  return (
   <>
      {/* first slide */}
      <div
                    className={`container-fluid ${solutionPageStyle.slideItem}`}
                  >
                    <div className="row">
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-2 order-sm-1 order-md-1 order-lg-1 ${solutionPageStyle.solLeftCol}`}
                      >
                        <div
                          className={`row ${solutionPageStyle.solLeftContent}`}
                        >
                          <div
                            className={`col-1 col-sm-1 col-md-1 col-lg-1 ${solutionPageStyle.scrollCol}`}
                          >
                            <img
                              src={solScroll}
                              className={solutionPageStyle.scrollPicStyle}
                              alt="scroll logo"
                            />
                          </div>
                          <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                            {/* <h1 className={solutionPageStyle.firstTextHeading}>
                              INDUSTRIAL
                            </h1> */}
                            <h2 className={solutionPageStyle.secondTextHeading}>
                              Rooftop PV
                            </h2>
                            <p className={solutionPageStyle.solParagraph}>
                              Rooftop P.V. is the most common way to go solar
                              and reduce your operating expenses. In this the
                              solar plant is installed in the client’s premises
                              on the Roof, Ground or both. The solar plant is
                              connected to a bi-directional meter that records
                              both the import of grid power and export of excess
                              solar power. Thus, helping client with a net bill
                              and reducing their expenses.{" "}
                            </p>
                            <h3 className={solutionPageStyle.subHead}>
                              Plant Location:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - On Site – At Customer Premises
                            </p>
                            <h3 className={solutionPageStyle.subHead}>
                              Connection Type:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - Net Meter <br />- Behind The Meter (BTM)
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Max. Solar Capacity:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - Net Meter – 1000 kWac <br />- BTM – As per
                              Consumption Analysis (No Capacity Limit)
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Installation Type:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - Rooftop (R.C.C. / Industrial Shed) <br />-
                              Ground Mount
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Additional Charges:
                            </h3>
                            <p className={solutionPageStyle.subPara}>None</p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-1 order-sm-2 order-md-2 order-lg-2 ${solutionPageStyle.solRightCol}`}
                      ></div>
                    </div>
                  </div>
   </>
  )
}

export default Rooftop