import solutionStyle from "./solutions.module.scss";
import cardOne from "../../../../Assets/EnergyFinancing/cardOne.png";
import cardTwo from "../../../../Assets/EnergyFinancing/cardTwo.png";
import cardThree from "../../../../Assets/EnergyFinancing/cardThree.png";
import cardFour from "../../../../Assets/EnergyFinancing/cardFour.png";

import SolutionsCard from "../../../../Components/SolutionsCard";
import { NavLink } from "react-router-dom";

const Solutions = ({handleLinkClick}) => {


const SolutionsDataFirst = [
    {
        image: cardOne,
        heading: 'P.P.A. Power Purchase',
        subHead1: 'Term(Years)',
        subHead2: 'Down Payment',
        subHead3: 'Plant Ownership',
        subHead4: '0&M',
        subHead5: 'GST Benefit',
        subHead6: 'Payment Terms',
        subHead1Value: '12 - 20',
        subHead2Value: '0%',
        subHead3Value: 'NLE',
        subHead4Value: 'NLE',
        subHead5Value: 'No',
        subHead6Value: 'Monthly Variable based on power generated',
    },

    {
        image: cardTwo,
        heading: 'CRex Credit Extension',
        subHead1: 'Term(Years)',
        subHead2: 'Down Payment',
        subHead3: 'Plant Ownership',
        subHead4: '0&M',
        subHead5: 'GST Benefit',
        subHead6: 'Payment Terms',
        subHead1Value: '1 - 2',
        subHead2Value: '25%',
        subHead3Value: 'Client',
        subHead4Value: 'Client',
        subHead5Value: 'Yes',
        subHead6Value: '25% / 50% Every Year + Monthly CRex Fees',
    },
    {   image: cardThree,
        heading: 'S.R.A. Solar Rent',
        subHead1: 'Term(Years)',
        subHead2: 'Down Payment',
        subHead3: 'Plant Ownership',
        subHead4: '0&M',
        subHead5: 'GST Benefit',
        subHead6: 'Payment Terms',
        subHead1Value: '8 - 15',
        subHead2Value: '15%',
        subHead3Value: 'NLE',
        subHead4Value: 'NLE',
        subHead5Value: 'Yes',
        subHead6Value: 'Monthly Fixed',
    },
    {   image: cardFour,
        heading: 'Deferred Payment',
        subHead1: 'Term(Years)',
        subHead2: 'Down Payment',
        subHead3: 'Plant Ownership',
        subHead4: '0&M',
        subHead5: 'GST Benefit',
        subHead6: 'Payment Terms',
        subHead1Value: '<5',
        subHead2Value: '15%',
        subHead3Value: 'Client',
        subHead4Value: 'Client',
        subHead5Value: 'Yes',
        subHead6Value: 'Monthly Fixed',
    }
]


// const SolutionsDataSecond = [

//     {   image: cardThree,
//         heading: 'S.R.A. Solar Rent',
//         subHead1: 'Term(Years)',
//         subHead2: 'Down Payment',
//         subHead3: 'Plant Ownership',
//         subHead4: '0&M',
//         subHead5: 'GST Benefit',
//         subHead6: 'Payment Terms',
//         subHead1Value: '8 - 15',
//         subHead2Value: '15%',
//         subHead3Value: 'NLE',
//         subHead4Value: 'NLE',
//         subHead5Value: 'Yes',
//         subHead6Value: 'Monthly Fixed',
//     },

//     {   image: cardFour,
//         heading: 'Deferred Payment',
//         subHead1: 'Term(Years)',
//         subHead2: 'Down Payment',
//         subHead3: 'Plant Ownership',
//         subHead4: '0&M',
//         subHead5: 'GST Benefit',
//         subHead6: 'Payment Terms',
//         subHead1Value: '<5',
//         subHead2Value: '15%',
//         subHead3Value: 'Client',
//         subHead4Value: 'Client',
//         subHead5Value: 'Yes',
//         subHead6Value: 'Monthly Fixed',
//     }
// ]


    return (
        <>
            <div className={`container-fluid ${solutionStyle.solutionsContainer}`}>
                <section className={solutionStyle.energyFirstSection}>

                    <h1>Financing  <span>Contracts</span></h1>

                    <div className="row">

                    {
                                SolutionsDataFirst?.map((items) => {
                                    return(
                                        <>
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                           <a href={"/getSolar"}  onClick={() => handleLinkClick(6)} > <SolutionsCard data={items} /></a>
                                        </div>
                                        </>
                                    )
                                })
                            }
                    </div>
                </section>
            </div>
        </>
    )
}

export default Solutions;