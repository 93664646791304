import introStyle from "./introduction.module.scss";
import introLogo from "../../../../Assets/Home/introLogo.png";

const Introduction = () => {
  return (
    <div className={`container-fluid ${introStyle.introContainer}`}>
     
        <img src={introLogo} alt="introduction section logo" />
        <p>a step forward towards a <br/> <span style={{color:"#7FAB55"}}>carbon-neutral</span> <span style={{color:"#0E265E"}}>world</span></p>
     
    </div>
  );
};

export default Introduction;
