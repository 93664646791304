import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CareerIntro from "./Components/CareerIntro/CareerIntro";
import careerStyle from "./careerStyle.module.scss";
import Positions from "./Components/Positions/Positions";
// import Role from "./Components/Role/Role";
import uploadPic from "../../Assets/Career/uploadPic.svg";
import modalClosePic from "../../Assets/Career/modalClosePic.svg";
import { toast } from "react-toastify";
import axios from "axios";

let axiosConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

let axiosResumeConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const Career = () => {
  const [modalActive, setModalActive] = useState(false);
  const [resume, setResume] = useState(null);
  const [hasFileError, setHasFileError] = useState(false);
  const [message, setMessage] = useState();
  const [carFormData, setCarFormData] = useState({
    userName: "",
    userMail: "",
    roleType: "",
    resume: "",
  });

  const handleModalClick = () => {
    setModalActive(!modalActive);
    setCarFormData({
      userName: "",
      userMail: "",
      roleType: "",
      resume: "",
    });
  };

  const handleCarChange = (e) => {
    setCarFormData({ ...carFormData, [e.target.name]: [e.target.value] });
  };

  const handleFileUpload = (e) => {
    setResume(e.target.files[0]);
    setHasFileError(false);
  };

  const handleRemoveFile = () => {
    setResume(null);
  };

  const handleCarSubmit = (url) => {
 
    if (!resume) {
      setHasFileError(true);
      return;
    }

    var finalCarData = {
      to: "vasupokhriyal123@gmail.com",
      text: "here i am",
      
      html: `<html>username: ${carFormData.userName}, userMail: ${carFormData.userMail}, roleType: ${carFormData.roleType}, resume: ${url[0]}</html>`,
      tabType: "2",
    };

    axios
      .post(
        "https://ec2-43-204-109-132.ap-south-1.compute.amazonaws.com/api/v1/nle/send_email_contactus",
        finalCarData,
        axiosConfig
      )
      .then((res) => {
        setMessage("Contact Request Sent");
        toast.success("Thank you for contacting us!");
        setCarFormData({
          userName: "",
          userMail: "",
          roleType: "",
          resume: "",
        });
        setTimeout(() => {
          setMessage();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message + "Try again after some time");
        setMessage(err.message + "Try again after some time");
      });
   

    // Handle form submission
  };

  const handleResumeUpload = (e) => {
    e.preventDefault();

    let resumeFormData = new FormData();

    resumeFormData.append("file", resume);

    axios
      .post(
        "https://ec2-43-204-109-132.ap-south-1.compute.amazonaws.com/api/v1/upload",
        resumeFormData,
        axiosResumeConfig
      )
      .then((res) => {
        console.log("response", res);
        let resumeUrl = res?.data.data;

        console.log("response", res);
        console.log("resumeUrl", resumeUrl);

        handleCarSubmit(resumeUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="shreyas" content="Place the meta description text here." />
        <title>NLE</title>
        <link rel="canonical" href="/" />
      </Helmet>
      <section className={careerStyle.careerIntroSection}>
        <CareerIntro  
          handleModalClick={handleModalClick}
         />
      </section>
      <section className={careerStyle.positionSection}>
        <Positions
          handleModalClick={handleModalClick}
          modalActive={modalActive}
        />
      </section>
      {/* <section className={careerStyle.roleSection}>
        <Role handleModalClick={handleModalClick} modalActive={modalActive} />
      </section> */}

      {modalActive && (
        <>
          <div
            className={careerStyle.backDrop}
            onClick={handleModalClick}
          ></div>

          <div className={careerStyle.modalContainer}>
            <h1>
              Apply <span>Here</span>
            </h1>
            <p>Thank you for showing your interest in joining the NLE</p>
            <img
              className={careerStyle.modalClosePic}
              src={modalClosePic}
              alt="modal close pic"
              onClick={handleModalClick}
            />

            <form onSubmit={handleResumeUpload}>
              <input
                required
                type="text"
                className={`form-control border-0 border-bottom ${careerStyle.modalField}`}
                placeholder="Your Name"
                name="userName"
                value={carFormData?.userName}
                onChange={handleCarChange}
              />
              <input
                required
                type="email"
                className={`form-control border-0 border-bottom ${careerStyle.modalField}`}
                placeholder="email"
                name="userMail"
                value={carFormData?.userMail}
                onChange={handleCarChange}
              />

              <select
                name="roleType"
                value={carFormData?.roleType}
                onChange={handleCarChange}
                className={`form-select border-0 border-bottom ${careerStyle.modalField}`}
                required
              >
                <option className={careerStyle.modalField} value="">
                  Select Role
                </option>
                <option className={careerStyle.modalField} value="UI Designer">
                DESIGN ENGINEER – TECHNICAL SALES
                </option>
                <option
                  className={careerStyle.modalField}
                  value="Front-End Developer"
                >
                 PROJECT ENGINEER 
                </option>
                <option
                  className={careerStyle.modalField}
                  value="Motion Designer"
                >
                  EXECUTIVE ASSISTANT (ACCOUNTS)
                </option>
                <option className={careerStyle.modalField} value="Illustrator">
                MANAGER - BUSINESS DEVELOPMENT
                </option>
              </select>

              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e)}
                name="resumeFile"
              />

              {/* the file upload design begins */}

              <div className={careerStyle.fileUploadDiv}>
                <div className={careerStyle.fileUploadBox}>
                  <div
                    style={{ display: resume && "none" }}
                    className={careerStyle.fileUploadContent}
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <img src={uploadPic} alt="file upload pic" />
                    <p>Upload your Resume</p>
                  </div>

                  {hasFileError && (
                    <p className={careerStyle.errorMsg}>
                      Please upload a file!!
                    </p>
                  )}

                  {resume && (
                    <>
                      <p>Selected file: {resume.name}</p>
                      <img
                        className={careerStyle.removeFilePic}
                        src={modalClosePic}
                        alt="remove file pic"
                        onClick={handleRemoveFile}
                      />
                    </>
                  )}
                </div>

                <p>
                  Attach file. File size of your documents should not exceed
                  10MB
                </p>
              </div>

              {/* the file upload design ends */}

              <button type="submit" className={careerStyle.modalButton}>
                <p>Submit</p>
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default Career;
