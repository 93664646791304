import React from "react";
import { navLinks } from "../../../Constants/NavLinks";
import logo from "../../../Assets/header and footer/Layer1000.svg";
import styles from "./Footer.module.scss";

import facebook from "../../../Assets/header and footer/Facebook.svg";
import instagram from "../../../Assets/header and footer/Instagram.svg";
import dribble from "../../../Assets/header and footer/Dribbble.svg";
import linkedin from "../../../Assets/header and footer/LinkedIn.svg";
import twitter from "../../../Assets/header and footer/Twitter.svg";
// import { NavLink } from "react-router-dom";

const Footer = ({handleLinkClick}) => {
  return (
    <>
      {/* top footer container */}
      <footer
        className={`container-fluid ${styles.footerContainer}`}
        id={"Footer"}
      >
        {/* the footer-content-container */}
        <div className={`${styles.footerContent} `}>
          <div className={"row"}>
            <div
              className={`col-12 col-sm-2 col-md-2 col-lg-2 d-flex justify-content-start align-items-center ${styles.logoColumn}`}
            >
            <a href={"/"} onClick={() => handleLinkClick(5)}>
              <img src={logo} alt="logo" />
              </a>
            </div>
            <div
              className={`col-12 col-sm-7 col-md-7 col-lg-7 d-flex justify-content-start align-items-center ${styles.linkColumn}`}
            >
              <ul>
                <li >
                <a className="nav-link" href="https://ziziev.com/" target="_blank" rel="noreferrer">ZIZI</a>
                </li>
                <li >
                  <a
                    style={{ color: "black", textDecoration: "none" }}
                    href={"/solution"}
                    onClick={() => handleLinkClick(0)}
                  >
                    Solution
                  </a>
                </li>
                <li >
                  <a
                    style={{ color: "black", textDecoration: "none" }}
                    href="/energy-financing"
                    onClick={() => handleLinkClick(3)}
                  >
                    Energy Financing
                  </a>
                </li>
                <li >
                  <a
                    style={{ color: "black", textDecoration: "none" }}
                    href='/about-us'
                    onClick={() => handleLinkClick(4)}
                  >
                    About Us
                  </a>
                </li>
                <li >
                  <a
                    style={{ color: "black", textDecoration: "none" }}
                    href='/career'
                    onClick={() => handleLinkClick(4)}
                  >
                    Career
                  </a>
                </li>
                <li >
                  <a
                    style={{ color: "black", textDecoration: "none" }}
                    href="/getSolar"
                    onClick={() => handleLinkClick(6)}
                  >
                    Get Solar
                  </a>
                </li>
              </ul>
            </div>

            <div
              className={`col-12 col-sm-3 col-md-3 col-lg-3 ${styles.iconColumn}`}
            >
              <a href="https://www.facebook.com/northernlightenergy" rel="noreferrer" target="_blank" >
                <img src={facebook} alt="facebook logo" />
              </a>
              <a href="https://instagram.com/northernlightenergy?igshid=NTc4MTIwNjQ2YQ==" rel="noreferrer" target="_blank">
                <img src={instagram} alt="instagram logo" />
              </a>
              {/* <a href="/" rel="noreferrer">
                <img src={dribble} alt="dribble logo" />
              </a> */}
              <a href="https://www.linkedin.com/company/northernlightenergy/" rel="noreferrer" target="_blank">
                <img src={linkedin} alt="linkedin logo" />
              </a>
              {/* <a href="/" rel="noreferrer">
                <img src={twitter} alt="twitter logo" />
              </a> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
