import React, { useState } from "react";
import positionStyle from "./positionStyle.module.scss";
import posIcon1 from "../../../../Assets/Career/posIcon1.svg";
import posIcon2 from "../../../../Assets/Career/posIcon2.svg";
import posIcon3 from "../../../../Assets/Career/posIcon3.svg";
import posIcon4 from "../../../../Assets/Career/posIcon4.svg";
import posLocation from "../../../../Assets/Career/posLocation.svg";
import posTime from "../../../../Assets/Career/posTime.svg";
import posAdd from "../../../../Assets/Career/posAdd.svg";
import posSubtract from "../../../../Assets/Career/posSubtract.svg";

const Positions = ({ handleModalClick }) => {
  const [posOperator1, setPosOperator1] = useState(false);
  const [posOperator2, setPosOperator2] = useState(true);
  const [posOperator3, setPosOperator3] = useState(true);
  const [posOperator4, setPosOperator4] = useState(true);

  const handlePos1Click = () => {
    setPosOperator1(!posOperator1);
  };

  const handlePos2Click = () => {
    setPosOperator2(!posOperator2);
  };

  const handlePos3Click = () => {
    setPosOperator3(!posOperator3);
  };

  const handlePos4Click = () => {
    setPosOperator4(!posOperator4);
  };

  return (
    <>
      <div
        id="positions"
        className={`container-fluid ${positionStyle.positionContainer}`}
      >
        <div className="row">
          <div className={`col-md-6 col-lg-6 ${positionStyle.posIntroText}`}>
            <h1>
              Open <span>Positions</span>
            </h1>
            {/* <p>
              This is what we’re crazy about, and we’re looking for the ones who
              feel the same.
            </p> */}
          </div>
          <div
            className={`col-md-12 col-lg-12 ${positionStyle.roleCol}`}
            onClick={() => handlePos1Click()}
          >
            <div className="row">
              {/* first icon */}
              <div
                className={`col-11 col-sm-4 col-md-4 col-lg-6 ${positionStyle.posFirstCol}`}
              >
                <img src={posIcon1} alt="pos icon 1" />
                <div className={positionStyle.roleDescription}>
                  <p>DESIGN ENGINEER – TECHNICAL SALES</p>
                  <p className={positionStyle.roleLocation}>Pune, Full-Time</p>
                </div>
              </div>

              {/* second icon */}
              <div
                className={`col-12 col-sm-7 col-md-7 col-lg-5 ${positionStyle.posSecondCol}`}
              >
                <img src={posLocation} alt="pos Location" />
                <p>Pune</p>
                <img
                  className={positionStyle.clockPic}
                  src={posTime}
                  alt="pos time"
                />
                <p>Full-time</p>
              </div>

              {/* third icon */}
              <div
                className={`col-1 col-sm-1 col-md-1 col-lg-1 ${positionStyle.posThirdCol}`}
              >
                <img
                  src={posOperator1 ? posAdd : posSubtract}
                  alt="pos icon 1"
                />
              </div>
            </div>

            {!posOperator1 && (
              <>
                <div className={positionStyle.posDescripText}>
                  <h1>Job Description</h1>
                  <p>
                    An Electrical Engineer with experience in designing Solar
                    P.V. Plant (AC Sizing, Earthing, DC Cable Routing and
                    Grouping, Distribution Box Design, P.V. Plant Diagnosis,
                    Energy Storage Design, Mounting Structural Coordination) and
                    technical sales with knowledge of Electrical Safety
                    Standards (IEC,IS, IEEE) and State Discom Policies.
                  </p>
                  <ul className={positionStyle.posUl}>
                    <li>
                      <p>B.Tech Electrical Engineer</p>
                    </li>

                    <li>
                      <p>Minimum 3 to 5 years of experience</p>
                    </li>

                    <li>
                      <p>
                        Conduct site feasibility analysis and prepare proposals
                      </p>
                    </li>

                    <li>
                      <p>
                        Proficiency in PV Syst, Auto CAD, 3ds Max, Sketchup, MS
                        Excel
                      </p>
                    </li>
                    <li>
                      <p>
                        Proficiency in estimating and quantifying detailed
                        B.O.M.{" "}
                      </p>
                    </li>
                    <li>
                      <p>
                        In-depth knowledge of Solar Inverter Troubleshooting
                      </p>
                    </li>

                    <li>
                      <p>Assistance in project execution and troubleshooting</p>
                    </li>

                    <li>
                      <p>
                        Experience in renewable energy consulting or Solar
                        E.P.C. is desirable{" "}
                      </p>
                    </li>

                    <li>
                      <p>
                        Experience and Knowledge of HT/HV Systems is desirable
                      </p>
                    </li>
                  </ul>

                  {/* <h2>Job Requirements</h2> */}

                  {/* <ul className={positionStyle.posUl}>
                    <li>
                      <p>
                        Feugiat pretium nibh ipsum consequat nisl. Pellentesque
                        habitant morbi.
                      </p>
                    </li>

                    <li>
                      <p>
                        Est placerat in egestas erat imperdiet sed euismod nisi.
                        Integer quis auctor elit.
                      </p>
                    </li>

                    <li>
                      <p>
                        Purus sit amet luctus venenatis lectus magna fringilla
                        urna.
                      </p>
                    </li>

                    <li>
                      <p>
                        Neque sodales ut etiam sit amet nisl purus non tellus
                        orci ac auctor
                      </p>
                    </li>
                  </ul> */}

                  <button
                    className={positionStyle.posApplyButton}
                    onClick={handleModalClick}
                  >
                    <p>Apply Now</p>
                  </button>
                </div>
              </>
            )}
          </div>

          {/* second col */}
          <div
            className={`col-md-12 col-lg-12 ${positionStyle.roleCol}`}
            onClick={() => handlePos2Click()}
          >
            <div className="row">
              {/* first icon */}
              <div
                className={`col-11 col-sm-5 col-md-5 col-lg-6 ${positionStyle.posFirstCol}`}
              >
                <img src={posIcon2} alt="pos icon 1" />

                <div className={positionStyle.roleDescription}>
                  <p>PROJECT ENGINEER </p>
                  <p className={positionStyle.roleLocation}>
                    Pune, Full-Time
                  </p>
                </div>
              </div>

              {/* second icon */}
              <div
                className={`col-12 col-sm-6 col-md-6 col-lg-5 ${positionStyle.posSecondCol}`}
              >
                <img src={posLocation} alt="pos Location" />
                <p>Pune</p>
                <img
                  className={positionStyle.clockPic}
                  src={posTime}
                  alt="pos time"
                />
                <p>Full-time</p>
              </div>

              {/* third icon */}
              <div
                className={`col-1 col-sm-1 col-md-1 col-lg-1 ${positionStyle.posThirdCol}`}
              >
                <img
                  src={posOperator2 ? posAdd : posSubtract}
                  alt="pos icon 1"
                />
              </div>
            </div>

            {!posOperator2 && (
              <>
                <div className={positionStyle.posDescripText}>
                  <h1>Job Description</h1>
                  <p>
                    An Electrical Engineer with experience of 2-4 Years in Solar
                    PV Plant Installation, project management and post
                    commissioning support and maintenance. Experience with state
                    Discom liaison for permissions and certifications.
                  </p>
                  <ul className={positionStyle.posUl}>
                    <li>
                      <p>Engineering Degree</p>
                    </li>

                    <li>
                      <p>Minimum experience of 2 to 4 years</p>
                    </li>

                    <li>
                      <p>
                        Responsible for project execution and post commissioning
                        maintenance (Including structural and electrical works).
                      </p>
                    </li>

                    <li>
                      <p>
                        Experience in solar inverter installation and
                        troubleshooting.
                      </p>
                    </li>

                    <li>
                      <p>
                        Proficient in Documentation, Material Management, Labour
                        Management, Safety Norms
                      </p>
                    </li>

                    <li>
                      <p>
                        Coordinating with state discoms and regulatory bodies
                        for grid connectivity permissions.
                      </p>
                    </li>

                    <li>
                      <p>Maintaining safety environment at site for workers.</p>
                    </li>

                    <li>
                      <p>
                        Inspection of Solar P.V. Power plant before handing over
                        to clients.
                      </p>
                    </li>

                    <li>
                      <p>
                        Coordination with design engineer at various stages of
                        project execution
                      </p>
                    </li>
                  </ul>

                  {/* <h2>Job Requirements</h2>

                  <ul className={positionStyle.posUl}>
                    <li>
                      <p>
                        Feugiat pretium nibh ipsum consequat nisl. Pellentesque
                        habitant morbi.
                      </p>
                    </li>

                    <li>
                      <p>
                        Est placerat in egestas erat imperdiet sed euismod nisi.
                        Integer quis auctor elit.
                      </p>
                    </li>

                    <li>
                      <p>
                        Purus sit amet luctus venenatis lectus magna fringilla
                        urna.
                      </p>
                    </li>

                    <li>
                      <p>
                        Neque sodales ut etiam sit amet nisl purus non tellus
                        orci ac auctor
                      </p>
                    </li>
                  </ul> */}

                  <button
                    className={positionStyle.posApplyButton}
                    onClick={handleModalClick}
                  >
                    <p>Apply Now</p>
                  </button>
                </div>
              </>
            )}
          </div>

          {/* third icon */}
          <div
            className={`col-md-12 col-lg-12 ${positionStyle.roleCol}`}
            onClick={() => handlePos3Click()}
          >
            <div className="row">
              {/* first icon */}
              <div
                className={`col-11 col-sm-4 col-md-4 col-lg-6 ${positionStyle.posFirstCol}`}
              >
                <img src={posIcon3} alt="pos icon 1" />

                <div className={positionStyle.roleDescription}>
                  <p>EXECUTIVE ASSISTANT (ACCOUNTS)</p>
                  <p className={positionStyle.roleLocation}>Pune, Full-Time</p>
                </div>
              </div>

              {/* second icon */}
              <div
                className={`col-12 col-sm-7 col-md-7 col-lg-5 ${positionStyle.posSecondCol}`}
              >
                <img src={posLocation} alt="pos Location" />
                <p>Pune</p>
                <img
                  className={positionStyle.clockPic}
                  src={posTime}
                  alt="pos time"
                />
                <p>Full-time</p>
              </div>

              {/* third icon */}
              <div
                className={`col-1 col-sm-1 col-md-1 col-lg-1 ${positionStyle.posThirdCol}`}
              >
                <img
                  src={posOperator3 ? posAdd : posSubtract}
                  alt="pos icon 1"
                />
              </div>
            </div>

            {!posOperator3 && (
              <>
                <div className={positionStyle.posDescripText}>
                  <h1>Job Description</h1>
                  <p>
                    An experienced accountant with minimum 2-4 years’ of work
                    experience at a CA Firm or an Engineering Firm. Proficient
                    in Tally, MS Excel and experience of coordinating with
                    Consultants for GST, TDS, and IT Return. Experience in admin
                    work for Director is desirable. Proficiency in english is a
                    must.
                  </p>
                  <ul className={positionStyle.posUl}>
                    <li>
                      <p>- M.Com (MBA in Finance is a plus)</p>
                    </li>

                    <li>
                      <p>2-4 years of experience in accounting</p>
                    </li>

                    <li>
                      <p>
                        Proficient in Tally, MS Excel, Research and Reporting on
                        topics provided
                      </p>
                    </li>

                    <li>
                      <p>
                        Conducting Daily Data Entry, Book Keeping and
                        documentation
                      </p>
                    </li>

                    <li>
                      <p>Knowledge of GST Compliance, TDS is desirable </p>
                    </li>

                    <li>
                      <p>
                        Proficient in English with knowledge of email
                        composition{" "}
                      </p>
                    </li>

                    <li>
                      <p>
                        Organize schedules, appointments, and completion of task
                        with full responsibility{" "}
                      </p>
                    </li>

                    <li>
                      <p>
                        Answering phones, taking messages, recording minutes of
                        meeting{" "}
                      </p>
                    </li>

                    <li>
                      <p>
                        Completing admin work like logistics, office well-being,
                        travel etc.{" "}
                      </p>
                    </li>
                  </ul>

                  {/* <h2>Job Requirements</h2>

                  <ul className={positionStyle.posUl}>
                    <li>
                      <p>
                        Feugiat pretium nibh ipsum consequat nisl. Pellentesque
                        habitant morbi.
                      </p>
                    </li>

                    <li>
                      <p>
                        Est placerat in egestas erat imperdiet sed euismod nisi.
                        Integer quis auctor elit.
                      </p>
                    </li>

                    <li>
                      <p>
                        Purus sit amet luctus venenatis lectus magna fringilla
                        urna.
                      </p>
                    </li>

                    <li>
                      <p>
                        Neque sodales ut etiam sit amet nisl purus non tellus
                        orci ac auctor
                      </p>
                    </li>
                  </ul> */}

                  <button
                    className={positionStyle.posApplyButton}
                    onClick={handleModalClick}
                  >
                    <p>Apply Now</p>
                  </button>
                </div>
              </>
            )}
          </div>

          {/* fourth icon */}
          <div
            className={`col-md-12 col-lg-12 ${positionStyle.roleCol}`}
            onClick={() => handlePos4Click()}
          >
            <div className="row">
              {/* first icon */}
              <div
                className={`col-11 col-sm-4 col-md-4 col-lg-6 ${positionStyle.posFirstCol}`}
              >
                <img src={posIcon4} alt="pos icon 1" />
                <div className={positionStyle.roleDescription}>
                  <p>MANAGER - BUSINESS DEVELOPMENT</p>
                  <p className={positionStyle.roleLocation}>Pune, Full-Time</p>
                </div>
              </div>

              {/* second icon */}
              <div
                className={`col-12 col-sm-7 col-md-7 col-lg-5 ${positionStyle.posSecondCol}`}
              >
                <img src={posLocation} alt="pos Location" />
                <p>Pune</p>
                <img
                  className={positionStyle.clockPic}
                  src={posTime}
                  alt="pos time"
                />
                <p>Full-time</p>
              </div>

              {/* third icon */}
              <div
                className={`col-1 col-sm-1 col-md-1 col-lg-1 ${positionStyle.posThirdCol}`}
              >
                <img
                  src={posOperator4 ? posAdd : posSubtract}
                  alt="pos icon 1"
                />
              </div>
            </div>

            {!posOperator4 && (
              <>
                <div className={positionStyle.posDescripText}>
                  <h1>Job Description</h1>
                  <p>
                  An experienced sales manager with minimum 3-5years’ of work experience in Solar P.V. projects with excellent networking and marketing skills.
                  </p>
                  <ul className={positionStyle.posUl}>
                    <li>
                      <p>
                      	Responsible for business development of solar rooftops projects.
                      </p>
                    </li>

                    <li>
                      <p>
                      Commercial qualification and market mapping to identify potential customers.
                      </p>
                    </li>

                    <li>
                      <p>
                     	Liaison with decision-makers to generate business.
                      </p>
                    </li>

                    <li>
                      <p>
                      Engage prospects through high ticket consultative sales approach to help them arriveat a decision on installation of solar projects.
                      </p>
                    </li>

                    <li>
                      <p>
                     	Assess and respond to the needs of each client through all phases of sales life cycle.
                      </p>
                    </li>

                    <li>
                      <p>
                      	Maintain close communication with the client to keep a track of changing requirements.
                      </p>
                    </li>

                    <li>
                      <p>
                      Create and make presentations to potential clients through direct communications.
                      </p>
                    </li>

                    <li>
                      <p>
                     Create and be accountable for all client proposals, contracts and any further documentation, following company procedures.
                      </p>
                    </li>
                  </ul>

                  {/* <h2>Job Requirements</h2>

                  <ul className={positionStyle.posUl}>
                    <li>
                      <p>
                        Feugiat pretium nibh ipsum consequat nisl. Pellentesque
                        habitant morbi.
                      </p>
                    </li>

                    <li>
                      <p>
                        Est placerat in egestas erat imperdiet sed euismod nisi.
                        Integer quis auctor elit.
                      </p>
                    </li>

                    <li>
                      <p>
                        Purus sit amet luctus venenatis lectus magna fringilla
                        urna.
                      </p>
                    </li>

                    <li>
                      <p>
                        Neque sodales ut etiam sit amet nisl purus non tellus
                        orci ac auctor
                      </p>
                    </li>
                  </ul> */}

                  <button
                    className={positionStyle.posApplyButton}
                    onClick={handleModalClick}
                  >
                    <p>Apply Now</p>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Positions;
