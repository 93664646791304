import React from "react";
import solutionPageStyle from "../Pages/Solution/solution.module.scss";
import solScroll from "../Assets/Solution/solScroll.svg";

const Hydrosolar = () => {
  return (
    <>
      {/* fifth slide */}
      <div className={`container-fluid ${solutionPageStyle.slideItem}`}>
        <div className="row">
          <div
            className={`col-12 col-sm-6 col-md-6 col-lg-6 order-2 order-sm-1 order-md-1 order-lg-1 ${solutionPageStyle.solLeftCol}`}
          >
            <div className={`row ${solutionPageStyle.solLeftContent}`}>
              <div
                className={`col-1 col-sm-1 col-md-1 col-lg-1 ${solutionPageStyle.scrollCol}`}
              >
                <img
                  src={solScroll}
                  className={solutionPageStyle.scrollPicStyle}
                  alt="scroll logo"
                />
              </div>
              <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                {/* <h1 className={solutionPageStyle.firstTextHeading}>
                  INDUSTRIAL
                </h1> */}
                <h2 className={solutionPageStyle.secondTextHeading}>
                  Decentralized Hydrogen SOLAR
                </h2>
                <p className={solutionPageStyle.solParagraph}>
                  Decentralized Hydrogen Solari.e.D.H.S., is a power solution by
                  Northern Light Energy where your base load is compensated by a
                  gas (natural gas / hydrogen) based power plant (onsite) which
                  is integrated with a solar plant (onsite or offsite) to
                  provide, uninterrupted, low cost, green power to our clients
                  especially, mission critical industries like Hospitals, Data
                  Centers etc. D.H.S. helps our clients reach a sustainable
                  electricity connected future by overcoming grid power
                  challenges like, <br />
                  - volatile grid unreliable power <br />
                  - intermittency of renewables
                  <br />
                  - old in-efficient coal plants lead to increase in grid power
                  trend
                  <br />
                  - volatility of fossil fuel (diesel / coal)
                  <br />
                  - leads to upward price trend
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div
            className={`col-12 col-sm-6 col-md-6 col-lg-6 order-1 order-sm-2 order-md-2 order-lg-2 ${solutionPageStyle.solRightCol}`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Hydrosolar;
