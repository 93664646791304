import React from "react";
import timelineStyle from "./aboutTimeline.module.scss";
import aboutTime1 from "../../../../Assets/AboutUs/aboutTime1.svg";
import aboutTime2 from "../../../../Assets/AboutUs/aboutTime2.svg";
import aboutTime3 from "../../../../Assets/AboutUs/aboutTime3.svg";
import aboutTime4 from "../../../../Assets/AboutUs/aboutTime4.svg";
import aboutTimeHorizon from "../../../../Assets/AboutUs/aboutTimeHorizon.svg";
import AboutTimeCompo from "../../../../Components/AboutTimeCompo";

const AboutTimeline = () => {
 
    const timelineContent = [
    {
      image: aboutTime1,
      heading: "Founded",
      // para: "Lorem ipsum dolor sit amet, consectetur ",
      year: "2019",
    },
    {
      image: aboutTime2,
      heading: "First 1MW Project",
      // para: "Lorem ipsum dolor sit amet, consectetur ",
      year: "2019",
    },
    {
      image: aboutTime3,
      heading: "Raised 4M $",
      // para: "Lorem ipsum dolor sit amet, consectetur ",
      year: "2021",
    },
    {
      image: aboutTime4,
      heading: "30 MW Solar Park",
      // para: "30 MW Solar Park",
      year: "2023",
    },
  ];

  return (
    <>
      <div className={`container-fluid ${timelineStyle.timelineContainer}`}>

      <div className={timelineStyle.columnBox}>
      <div className="row gx-0 gx-sm-0">
      {
        timelineContent?.map((item) => {
            return(
                <>
                    <div className={`col-sm-3 col-md-3 col-lg-3 d-flex align-items-end ${timelineStyle.outerTimeContainer}`}>
                        <AboutTimeCompo data={item} />
                    </div>
                </>
            )
        })
      }

      </div>

      <img src={aboutTimeHorizon} className={timelineStyle.lineStyle} alt="The Horizon"/>
      </div>
      
      </div>
    </>
  );
};

export default AboutTimeline;
