import React from 'react';
import solutionPageStyle from "../Pages/Solution/solution.module.scss";
import solScroll from "../Assets/Solution/solScroll.svg";

const Evcharging = () => {
  return (
    <>
          {/* fourth slide */}
          <div
                    className={`container-fluid ${solutionPageStyle.slideItem}`}
                  >
                    <div className="row">
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-2 order-sm-1 order-md-1 order-lg-1 ${solutionPageStyle.solLeftCol}`}
                      >
                        <div
                          className={`row ${solutionPageStyle.solLeftContent}`}
                        >
                          <div
                            className={`col-1 col-sm-1 col-md-1 col-lg-1 ${solutionPageStyle.scrollCol}`}
                          >
                            <img
                              src={solScroll}
                              className={solutionPageStyle.scrollPicStyle}
                              alt="scroll logo"
                            />
                          </div>
                          <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                            {/* <h1 className={solutionPageStyle.firstTextHeading}>
                              INDUSTRIAL
                            </h1> */}
                            <h2 className={solutionPageStyle.secondTextHeading}>
                              E.V. CHARGING{" "}
                            </h2>
                            <p className={solutionPageStyle.solParagraph}>
                              India is at the cusp of global sustainable
                              mobility revolution. With its hard-set targets of
                              30% new vehicles to be EV by 2030, charging
                              infrastructure is going to play a vital role.
                              NLE’s ZIZI provides a complete charging solution
                              for projects, individual customers and retail
                              investors looking to install charging stations.{" "}
                              <br />
                              <br />
                              ZIZI provides custom built robust quality EV
                              chargers with backend software for auto payment
                              collection, invoice generation, charger health
                              monitoring and real time charger status. With
                              ZIZI’s auto operating chargers, starting an EV
                              Charging Stations is extremely seamless and hassle
                              free.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-1 order-sm-2 order-md-2 order-lg-2 ${solutionPageStyle.solRightCol}`}
                      ></div>
                    </div>
                  </div>
    </>
  )
}

export default Evcharging;