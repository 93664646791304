import React from 'react';
import solutionPageStyle from "../Pages/Solution/solution.module.scss";
import solScroll from "../Assets/Solution/solScroll.svg";

const Bipv = () => {
  return (
    <>
           {/* sixth slide */}
           <div
                    className={`container-fluid ${solutionPageStyle.slideItem}`}
                  >
                    <div className="row">
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-2 order-sm-1 order-md-1 order-lg-1 ${solutionPageStyle.solLeftCol}`}
                      >
                        <div
                          className={`row ${solutionPageStyle.solLeftContent}`}
                        >
                          <div
                            className={`col-1 col-sm-1 col-md-1 col-lg-1 ${solutionPageStyle.scrollCol}`}
                          >
                            <img
                              src={solScroll}
                              className={solutionPageStyle.scrollPicStyle}
                              alt="scroll logo"
                            />
                          </div>
                          <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                            {/* <h1 className={solutionPageStyle.firstTextHeading}>
                              INDUSTRIAL
                            </h1> */}
                            <h2 className={solutionPageStyle.secondTextHeading}>
                              B.I.P.V.
                            </h2>
                            <p className={solutionPageStyle.solParagraph}>
                              NLE partnered with ONYX Solar to bring our
                              customers aesthetically pleasing building
                              integrated sustainable solar solution. One of the
                              best ways to use B.I.P.V. is with the Solar Roof
                              Solution. Solar Roof is totally leak-proof with
                              multiple layers of waterproofing and is made using
                              a specially coated material with high durability
                              and resistant to rust. Replace your roof, windows,
                              glass facades with revenue generating solar plant.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-1 order-sm-2 order-md-2 order-lg-2 ${solutionPageStyle.solRightCol}`}
                      ></div>
                    </div>
                  </div>

    </>
  )
}

export default Bipv;