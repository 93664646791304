import React from 'react';
import timelineStyle from "../Pages/AboutUs/Components/AboutTimeline/aboutTimeline.module.scss";

const AboutTimeCompo = ({data}) => {
    const {image, heading, para, year} = data;
  return (
    <>
       <div className={`container-fluid ${timelineStyle.timeCompoContainer}`}>
       <div className={`row gx-0 ${timelineStyle.timelineRow}`}>
        <div className={`col-10 col-sm-12 col-md-12 col-lg-12 order-2 order-sm-1 ${timelineStyle.timeText}`}>
        <img src={image} alt="the aboutTimeLine pics"/>
        <h1>{heading}</h1>
        {/* <p>{para}</p> */}
        </div>
        <div className={`col-2 col-sm-3 col-md-3 col-lg-3 order-1 order-sm-2 ${timelineStyle.timeYear}`}>
        <h2>{year}</h2>
        </div>
        </div>
        </div>
    </>
  )
}

export default AboutTimeCompo;