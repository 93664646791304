import BasicLayout from "../../Layouts/BasicLayout/BasicLayout";
import homeStyle from "./Home.module.scss";
import { Helmet } from "react-helmet";
import AboutUs from "./Components/AboutUs/AboutUs";
import Projects from "./Components/Projects/Projects";
import WhyNLE from "./Components/WhyNLE/WhyNLE";
import Introduction from "./Components/Introduction/Introduction";
import Vision from "./Components/Vision/Vision";

// import EnergyIntro from "../EnergyFinancing/Components/EnergyIntro/EnergyIntro";
// import Solutions from "../EnergyFinancing/Components/Solutions/Solutions";

const Home = ({handleLinkClick}) => {
  return (
    <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="shreyas"
            content="Place the meta description text here."
          />
          <title>NLE</title>
          <link rel="canonical" href="/" />
        </Helmet>
        {/* <div style={{ paddingRight: '0.75rem', paddingLeft: '0.75rem' }}> */}
        {/* <section className={homeStyle.headerSection}>
        <Header />
        </section> */}

        <section  className={homeStyle.homeIntroSection}>
          <Introduction />
        </section>
        <section className={homeStyle.aboutUsSection}>
          <AboutUs handleLinkClick={handleLinkClick} />
        </section>
        <section className={homeStyle.visionSection}>
          <Vision />
        </section>
      
          <Projects />
      
        <section className={homeStyle.whyNleSection}>
          <WhyNLE />
        </section>
  
        {/* <Footer /> */}
    </>
  );
};
export default Home;
