import "./App.scss";
import { Route, Routes } from "react-router-dom";
import { navLinks } from "./Constants/NavLinks";
import Home from "./Pages/Home/Home";
import BasicLayout from "./Layouts/BasicLayout/BasicLayout";
import { useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Solution from "./Pages/Solution/Solution";
import EnergyFinancing from "./Pages/EnergyFinancing/EnergyFinancing";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Career from "./Pages/Career/Career";
import GetSolar from "./Pages/GetSolar/GetSolar";


function App() {
  const [activeLink, setActiveLink] = useState(0);


  const handleLinkClick = (index) => {
    setActiveLink(index);
    window.scrollTo(0, 0);
  };

 

  

 

  return (
    <div className="App">
      <BasicLayout handleLinkClick={handleLinkClick} activeLink={activeLink}>
       
        <Routes>
          <Route path="/" exact element={<Home handleLinkClick={handleLinkClick}/>} />
          <Route path="/solution" exact element={<Solution/>} />
          <Route path="/energy-financing" exact element={<EnergyFinancing handleLinkClick={handleLinkClick}/>} />
          <Route path="/about-us" exact element={<AboutUs/>} />
          <Route path="/career" exact element={<Career/>} />
          <Route path="/getSolar" exact element={<GetSolar/>} />

          {/* {navLinks?.map((item, index) => {
            return (
              <Route
                key={index}
                path={item?.path}
                exact={item?.exact}
                element={item?.element}
              />
            );
          })} */}


        </Routes>
        <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
      </BasicLayout>
    </div>
  );
}

export default App;
