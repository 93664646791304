import React from 'react';
import solutionPageStyle from "../Pages/Solution/solution.module.scss";
import solScroll from "../Assets/Solution/solScroll.svg";

const Microgrid = () => {
  return (
    <>
          {/* second slide */}
          <div
                    className={`container-fluid ${solutionPageStyle.slideItem}`}
                  >
                    <div className="row">
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-2 order-sm-1 order-md-1 order-lg-1 ${solutionPageStyle.solLeftCol}`}
                      >
                        <div
                          className={`row ${solutionPageStyle.solLeftContent}`}
                        >
                          <div
                            className={`col-1 col-sm-1 col-md-1 col-lg-1 ${solutionPageStyle.scrollCol}`}
                          >
                            <img
                              src={solScroll}
                              className={solutionPageStyle.scrollPicStyle}
                              alt="scroll logo"
                            />
                          </div>
                          <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                            {/* <h1 className={solutionPageStyle.firstTextHeading}>
                              Industrial
                            </h1> */}
                            <h2 className={solutionPageStyle.secondTextHeading}>
                              MICROGRID (ESS)
                            </h2>
                            <p className={solutionPageStyle.solParagraph}>
                              Microgrids are best suited for clients facing
                              intermittent, fluctuating or high tariff rate
                              power. In this, the Solar Plant is connected with
                              an Energy Storage System that helps store the
                              excess power generation and lets you utilize it
                              when you require it. For clients thriving to
                              achieve 100% R.E., NLE integrates their microgrids
                              with Open Access and Carbon Credits to get Round
                              the Clock R.E.
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Plant Location:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - On Site – At Customer Premises
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Connection Type:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - Net Meter <br />- Behind The Meter (BTM)
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Max. Solar Capacity:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - As per Consumption Analysis (No Capacity Limit)
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Installation Type:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - Rooftop (R.C.C. / Industrial Shed) <br />-
                              Ground Mount
                            </p>

                            <h3 className={solutionPageStyle.subHead}>
                              Additional Charges:
                            </h3>
                            <p className={solutionPageStyle.subPara}>- None</p>

                            <h3 className={solutionPageStyle.subHead}>
                              Eligibility:
                            </h3>
                            <p className={solutionPageStyle.subPara}>
                              - Contract Demand > 1 MW
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-1 order-sm-2 order-md-2 order-lg-2 ${solutionPageStyle.solRightCol}`}
                      ></div>
                    </div>
                  </div>
    </>
  )
}

export default Microgrid;