import aboutStyle from "./aboutUs.module.scss";
import aboutUsScroll from "../../../../Assets/Home/aboutUsScroll.svg";
import aboutUsArrow from "../../../../Assets/Home/aboutUsArrow.svg";
import { NavLink } from "react-router-dom";

const AboutUs = ({handleLinkClick}) => {
  return (
    <>
      <div className={`container-fluid  ${aboutStyle.aboutContainer}`}>
        <div className="row gx-0 gx-sm-0">
          <div className={`col-12 col-sm-12 col-md-6 col-lg-6 ${aboutStyle.aboutLeftSection}`}>
            <div className={`container-fluid ${aboutStyle.aboutTextContainer}`}>
              <div className="row gx-0 gx-sm-0">
                <div className={`col-1 col-sm-1 col-md-1 col-lg-1 ${aboutStyle.scrollColumn}`}>
                  <img src={aboutUsScroll} className={aboutStyle.scrollImage} alt="scroll logo" />
                </div>
                <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                  <h6>About Us</h6>
                  <p style={{marginBottom:0}}>Northern Lights, is not only one of the most beautiful sight to witness on our
                     planet but also a very powerful representation of hope. Also known as Aurora
                     Borealis, it exudes the combined powers of the Nordic Gods of Sun and Wind. In
                     today’s world where we are turning towards renewables, this beautiful burning
                    rainbow bridge will help us cross all our hurdles to a,
                    </p>
                    <p><span style={{color:"#7FAB55"}}>sustainable</span><span> </span> <span style={{color:"purple", marginLeft:"1%"}} >energy - connected future.</span></p>
                  <p>
                    Following this bridge of hope philosophy, NLE embraces technology for 
                    delivering state-of-the-art green power solutions to our customer’s ever- growing 
                    energy needs and assisting them in being a part of the global RE100 initiative.
                  </p>
                  <div className={aboutStyle.aboutTextBottomContainer}>
                    <div className={aboutStyle.aboutTextBottom}>
                      <h5>$4m +</h5>
                      <p>Fund raised</p>
                    </div>
                    <div className={aboutStyle.aboutTextBottom}>
                      <h5>50 +</h5>
                      <p>Projects</p>
                    </div>
                  </div>
                    <a href={`/about-us`}  onClick={() => handleLinkClick(4)}><img src={aboutUsArrow} alt="arrow button" className={aboutStyle.aboutUsArrowButton}/></a>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`col-12 col-sm-12 col-md-6 col-lg-6 ${aboutStyle.aboutRightSection}`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
