import energyStyle from "./energyIntro.module.scss";
import energyIntroPic from "../../../../Assets/EnergyFinancing/EnergyFinancing.svg";

const EnergyIntro = () => {
  return (
    <>
      <div className={`container-fluid ${energyStyle.energyIntroContainer}`}>
        <div className="row">
          <div className={` col-md-6 col-lg-6 ${energyStyle.energyLeftColumn}`}>
            <h4>
              Energy <span> Financing</span>
            </h4>
            <p>
              In a Post COVID world, when business – as – usual resumes, maintaining cash flow may cause a struggle, as revenue takes a dip and expenses pile on. The most efficient way of getting through this turbulence may only be, by going frugal and curbing operating expenses.
            </p>
            <p>
              To assist our clients in curbing their electricity expenses, NLE partnered with one of the most environmentally conscious private equity firm based off Singapore, enabling us to provide capex financing for corporates prepared to make their clean energy switch
            </p>
            <p>
              NLE Project 0TM empowers you to seamlessly switch to solar and reduce your operating expenses (electricity bills) without having to bear the high initial capital investment.
            </p>
          </div>
          <div className={`col-md-6 col-lg-6 ${energyStyle.energyRightColumn}`}>
            <img src={energyIntroPic} alt="energy Intro pic" />
          </div>
        </div>
      </div>
    </>
  );
};

export default EnergyIntro;
