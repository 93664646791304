import { NavLink } from "react-router-dom";
import solutionStyle from "../Pages/EnergyFinancing/Components/Solutions/solutions.module.scss";


const SolutionsCard = ({ data }) => {

    // const {data} = props
    const { image, heading, subHead1, subHead1Value, subHead2, subHead2Value, subHead3, subHead3Value, subHead4, subHead4Value, subHead5, subHead5Value, subHead6, subHead6Value } = data;

    return (
        <>
            <div className={`card ${solutionStyle.cardDiv}`}>
                {/* <img class="card-img-top" src={image} alt="Card structure cap" /> */}
                <div class="card-body">
                    <div className={solutionStyle.solutionText}>
                        <h3>{heading}</h3>
                        <div className="row">
                            <div className="col-4 col-sm-4 col-md-3 col-lg-3">
                                <h4>{subHead1}</h4>
                                <h5>{subHead1Value}</h5>
                            </div>

                            <div className="col-4 col-sm-4 col-md-3 col-lg-3">
                                <h4>{subHead2}</h4>
                                <h5>{subHead2Value}</h5>
                            </div>

                            <div className="col-4 col-sm-4 col-md-3 col-lg-3">
                                <h4>{subHead3}</h4>
                                <h5>{subHead3Value}</h5>
                            </div>

                            <div className="col-4 col-sm-4 col-md-3 col-lg-3">
                                <h4>{subHead4}</h4>
                                <h5>{subHead4Value}</h5>
                            </div>

                            <div className="col-8 col-sm-8 col-md-6 col-lg-6 ">
                                <h4>{subHead6}</h4>
                                <h5>{subHead6Value}</h5>
                            </div>

                            <div className="col-4 col-sm-6 col-md-6 col-lg-6">
                                <h4>{subHead5}</h4>
                                <h5>{subHead5Value}</h5>
                            </div>

                            {/* <div className="col-12">
                            <NavLink to="/getSolar">
                                 <button className={solutionStyle.joinUsBtn}>Get Solar</button>
                             </NavLink>
                            </div>

                            <div className={`col-5 ${solutionStyle.mobileButtonCol}`}>
                             
                             <NavLink to="/getSolar">
                                 <button className={solutionStyle.joinUsBtnMobile}>Get Solar</button>
                             </NavLink>
                         </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default SolutionsCard;