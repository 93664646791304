import React from 'react';
import solutionPageStyle from "../Pages/Solution/solution.module.scss";
import solScroll from "../Assets/Solution/solScroll.svg";

const Bms = () => {
  return (
    <>
           {/* seventh slide */}
           <div
                    className={`container-fluid ${solutionPageStyle.slideItem}`}
                  >
                    <div className="row">
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-2 order-sm-1 order-md-1 order-lg-1 ${solutionPageStyle.solLeftCol}`}
                      >
                        <div
                          className={`row ${solutionPageStyle.solLeftContent}`}
                        >
                          <div
                            className={`col-1 col-sm-1 col-md-1 col-lg-1 ${solutionPageStyle.scrollCol}`}
                          >
                            <img
                              src={solScroll}
                              className={solutionPageStyle.scrollPicStyle}
                              alt="scroll logo"
                            />
                          </div>
                          <div className="col-11 col-sm-11 col-md-11 col-lg-11">
                            {/* <h1 className={solutionPageStyle.firstTextHeading}>
                              INDUSTRIAL
                            </h1> */}
                            <h2 className={solutionPageStyle.secondTextHeading}>
                              B.M.S. - AUTOMATION FOR UTILITY EFFICIENCY{" "}
                            </h2>
                            <p className={solutionPageStyle.solParagraph}>
                              Our wireless BMS technology delivers sustainable
                              facilities through real time 24/7 data collection,
                              providing facility owners and managers increased
                              information, trends and control.NLE keeps our
                              client informed and provides control of their
                              electricity, heating, cooling, water, diesel
                              generator and other utility equipment’s. <br />
                              <br />
                              This helps in achieving sustainability goals,
                              reduction in operating costs, creating a better
                              built environment for clients, employees and the
                              public. <br />
                              <br />
                              Make your buildings energy efficient by 25% <br />
                              Reduce your maintenance cost by 20% <br />
                              Reduce manpower requirement by 25% <br />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 order-1 order-sm-2 order-md-2 order-lg-2 ${solutionPageStyle.solRightCol}`}
                      ></div>
                    </div>
                  </div>
    </>
  )
}

export default Bms;