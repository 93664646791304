import React from 'react'
import { Helmet } from 'react-helmet';
import Footer from '../../Layouts/BasicLayout/Footer/Footer';
import EnergyIntro from './Components/EnergyIntro/EnergyIntro';
import Solutions from './Components/Solutions/Solutions';
import energyFinancingModule from "./energyFinancing.module.scss";
import Header from '../../Layouts/BasicLayout/Header/Header';



const EnergyFinancing = ({handleLinkClick}) => {
    return(
        <>
         <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="shreyas"
            content="Place the meta description text here."
          />
          <title>NLE</title>
          <link rel="canonical" href="/" />
        </Helmet>
        <section className={energyFinancingModule.energyIntroSection}>    
         <EnergyIntro />
         </section>
         <Solutions handleLinkClick={handleLinkClick}/>
       </>
    )
}

export default EnergyFinancing;