import React from 'react';
import aboutIntroStyle from "./aboutIntro.module.scss";
import picOne from "../../../../Assets/AboutUs/picOne.png";
import picTwo from "../../../../Assets/AboutUs/picTwo.png";


const AboutIntro = () => {
  return (
    <>
      <div className={`container-fluid ${aboutIntroStyle.aboutIntroContainer}`}>
        <div className='row gx-0 gx-sm-0'>
          <div className={`col-12 col-sm-12 col-md-6 col-lg-6 order-2 order-sm-1  ${aboutIntroStyle.introLeft}`}>
            <h1>About <span>Us</span></h1>
            <p>
              Understanding the impact on society of our reliance on fossil fuels for energy, led to the foundation of NLE
            </p>
            <p>
              NLE follows a vision to embrace technology for delivering a state-of- the-art solution to our customer’s ever-growing energy needs.
            </p>
            <p>
              Leveraging a world-class education from Carnegie Mellon and the experience of working on projects for Tesla and Saudi Aramco, NLE follows a Data-Driven approach emphasizing on design and innovation to conduct robust analysis. Helping us to provide our clients with investment grade solutions.
            </p>
            <p>
              An experienced project management team with the expertise of multi- megawatt installations has enabled NLE to engineer and implement premium solar P.V. solutions augmenting our client as
              a carbon – neutral brand.
            </p>

            <button className={aboutIntroStyle.companyProfileBtn}>
            <a style={{textDecoration:"none"}}>Company Profile</a>
            </button>
          </div>

          <div className={`col-12 col-sm-12 col-md-6 col-lg-6 order-1 order-sm-2  ${aboutIntroStyle.introRight}`}>
            <img src={picOne} className={aboutIntroStyle.introPicOne} alt="About Us Intro pic one" />
            <img src={picTwo} className={aboutIntroStyle.introPicTwo} alt="About Us Intro pic two" />
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutIntro;