import nleStyle from "./whyNle.module.scss";
import nleScroll from "../../../../Assets/Home/nleScroll.svg";

const WhyNLE = () => {
  return (
    <div className={nleStyle.nleContainer}>
      <div className={`container-fluid ${nleStyle.nleTextContainer}`}>
        <div className="row gx-0">
          <div className="col-1 col-sm-1 col-md-1 col-lg-1">
            <img src={nleScroll} alt="nle scroll" />
          </div>
          <div className="col-11 col-sm-11 col-md-11 col-lg-11">
            <h6>CLIMATE CHANGE IS HERE</h6>
            <p>
              Climate change displaces 62,000 people every-single day, i.e. people have to leave their house, their family land, and their livelihood. They have to leave all-things precious behind, only to start from the bottom again.
            </p>
            <p>
              Few help those in need, but most of us are unaware of the adversities the affected people go through.
            </p>
            <p style={{marginBottom:0}}>
              We all are too engrossed in getting through with our day and may not find the time to care for our planet, our home. But what we can do is make a choice.
           <br/>
              A choice, so commanding that it can help us build a better future.
              <br/>
              A choice, that will help us take a step forward towards a carbon-neutral future.
              <br/>
              A choice, that needs to be made by you.
            </p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default WhyNLE;