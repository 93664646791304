import React from "react";
import careerIntro from "./careerIntro.module.scss";
import carIntroPic1 from "../../../../Assets/Career/carIntroPic1.png";
import carIntroPic2 from "../../../../Assets/Career/carIntroPic2.png";
import carIntroPic3 from "../../../../Assets/Career/carIntroPic3.png";

const CareerIntro = ({handleModalClick}) => {
  return (
    <>
      <div className={`container-fluid ${careerIntro.careerIntroContainer}`}>
        <div className="row">
          <div className={`col-sm-6 col-md-6 col-lg-6 ${careerIntro.carIntroLeft}`}>
          <div className={careerIntro.carIntroText}>
            <h1 className={careerIntro.carIntroHeading}>
              Join our team and help ignite the beautiful burning bridge of hope, N.L.E.
            </h1>

            <p className={careerIntro.carIntroParagraph}>
            Please view the openings mentioned below to apply .You can also share your resume and we will get back to you with a right fit.
            </p>

            <div className={careerIntro.introBtnDiv}>
            <button className={careerIntro.carIntroButton}>
            <a style={{textDecoration:"none"}}href="#positions"><p>View Opening</p></a>
            </button>

            <button className={careerIntro.carIntroButton} onClick={handleModalClick}>
            <a style={{textDecoration:"none"}} ><p>Share Resume</p></a>
            </button>
            </div>
            
            </div>
          </div>
          <div
            className={`col-sm-6 col-md-6 col-lg-6 ${careerIntro.CareerRightSection}`}
          >
            {/* <img className={careerIntro.carIntroPic1} src={carIntroPic1} alt="career Intro pic 1"/>
            <img className={careerIntro.carIntroPic2} src={carIntroPic2} alt="career Intro pic 2"/> 
            <img className={careerIntro.carIntroPic3} src={carIntroPic3} alt="career Intro pic 3"/> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerIntro;
